<template>
  <!-- 全局头部组件 -->
  <div class="index-title no_select">
    <div class="logo">
      <img @click="goHome" class="logo-img  hand" alt="infoxmed" src="@/assets/img/newLog1o.png">
      <p class="slogan">数据技术驱动、赋能医生成长</p>
    </div>

    <div class="user-info" :class="{ 'not_login': isLogin }">
      <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal"
               @select="handleSelect" text-color="#fff" active-text-color="#fff">
        <el-menu-item v-for="item in optionList" :key="item.path" :index="item.path">
          <span v-if="!item.dropdown" class="menu-item-name" :class="{ 'isMark': item.isMark }">
            {{ item.name }}
          </span>

          <el-dropdown v-if="item.dropdown" placement="bottom" :show-timeout="10"
                       @command="dropdownChange">
            <span class="el-dropdown-link menu-item-name"
                  :class="{ 'isMark': msgMark && item.path === '/msgContent' }">
              {{ item.name }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="msg in getDropdownData(item)" :key="msg.name"
                                :disabled="$route.query.category === msg.category"
                                :command="msg.category" :divided="msg.divided">
                <div>
                  <span class="dropdown_msg_name">
                    <i v-if="msg.icon" class="iconfont" :class="msg.icon"></i>
                    {{ msg.name }}
                    <span v-if="msg.isMark" class="dropdown_mark"></span>
                  </span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
      </el-menu>
      <div v-if="userIds.includes(userId)" class=" enterprise_btn hand" @click="toEnterprisePage">
        企业版
      </div>
      <!-- <div class="enterprise_btn hand" @click="toDrugPage">
        药品信息
      </div> -->
      <!-- animated  :class="{ 'pulse' : donghua }" @click="register"   @mouseover="dong"-->
      <div class="login_box " v-if="isLogin">
        <span class="login_btn hand display_flex_center" @click="openLogin('login')">登录</span>
        <i class="line"></i>
        <span @click="openLogin('register')" class="login_btn hand display_flex_center">注册
        </span>
        <i class="degister_vip_Icon">送7天会员</i>
      </div>

      <div class="user  hand" v-else>
        <div class="tou-xoamng">
          <el-image :src="userInfo.header" fit="cover" alt="infoxmed">
            <div slot="error" class="image-slot">
              <img src="@/assets/img/tou.png" alt="infoxmed">
            </div>
          </el-image>
          <svg v-if="userInfo.isHy && !userInfo.enterpriseName && !$store.getters.userIpInfo.partnerId"
               t="1646289874939" class="icon svg_user_vip" viewBox="0 0 1024 1024" version="1.1"
               xmlns="http://www.w3.org/2000/svg" p-id="18249" width="16" height="16">
            <linearGradient x1="-4.4%" y1="3.5%" x2="58.5%" y2="104.9%" id="__gradient_vip2">
              <stop stop-color="#FFF1CC" offset="0%"></stop>
              <stop stop-color="#FFCD45" offset="36.9%"></stop>
              <stop stop-color="#FF7000" offset="100%"></stop>
            </linearGradient>
            <linearGradient x1="0%" y1="35.6%" x2="50%" y2="94.2%" id="__gradient_vip4">
              <stop stop-color="#FFF1CC" offset="0%"></stop>
              <stop stop-color="#FFCD45" offset="40.9%"></stop>
              <stop stop-color="#FF7000" offset="100%"></stop>
            </linearGradient>
            <path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m289.0496 327.3984L454.528 770.4576a19.1488 19.1488 0 0 1-15.0528 7.3472h-111.4624a19.2 19.2 0 0 1-19.1744-19.1488v-354.816a19.1744 19.1744 0 0 0-19.1488-19.1488H234.6752a19.1488 19.1488 0 0 1-19.0976-19.1488v-50.048a19.1232 19.1232 0 0 1 19.2-19.0464h181.5552a19.1488 19.1488 0 0 1 19.1488 19.1488v153.344a19.1488 19.1488 0 0 0 33.792 12.3648l150.3488-178.048a19.2256 19.2256 0 0 1 14.6432-6.8096h151.68a19.1488 19.1488 0 0 1 15.104 30.9504z"
                  fill="url(#__gradient_vip4)" p-id="18250"></path>
            <!-- #ffb332 -->
          </svg>
          <img v-if="userInfo.enterpriseName" class="svg_user_vip team_icon"
               src="@/assets/img/userInfo/team_icon.png" alt="infoxmed">
          <img v-if="!userInfo.enterpriseName && $store.getters.userIpInfo.partnerId"
               class="icon userip_icon svg_user_vip"
               src="https://img.infox-med.com/webImg/infoPc/icon/ip_icon.png" alt="">
        </div>
        <el-dropdown @command="personal">
          <span class="el-dropdown-link">
            {{ userInfo.name }}
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="userInfo">个人中心</el-dropdown-item>
            <el-dropdown-item v-if="!!fw2PassCount" command="fw2">医医科普</el-dropdown-item>
            <el-dropdown-item v-if="!!fw10PassCount" command="fw10">医患科普</el-dropdown-item>
            <el-dropdown-item command="feedback">反馈中心</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

  </div>
</template>
<script>
import { pageTurn } from '@/utils/index.js'
import { getUserFwPassCountByName } from '@/api/lw.js'
import merge from 'webpack-merge'
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'indexTitle',
  props: {
    submitUserUseTime: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      isShow: false, // 登录弹框
      QrCode: null, // 二维码图片
      qrimg: false, // 二维码刷新按钮
      isLogin: true, // 登录按钮控制
      // donghua: false, // 动画
      optionList: [
        {
          path: '/periodical',
          name: '期刊',
          countName: 'PC-期刊访问'
        },
        {
          path: '/guideline',
          name: '指南',
          countName: 'PC-指南访问'
        },
        {
          path: '/aiModule',
          name: 'AI专区',
          // dropdown: true,
          // dataKey: 'aiDropdownData',
          countName: 'PC-AI助手访问'
        },
        {
          path: '/literatureKing',
          name: '科研助手',
          countName: 'PC-科研助手访问'
        },
        {
          path: '/infoCollege',
          name: '学院',
          countName: 'PC-学院访问'
        },
        {
          path: '/guoZiRan',
          name: '国自然',
          countName: 'PC-国自然访问'
        },
        // {
        //   path: '/news',
        //   name: '资讯'
        // },
        {
          path: '/msgContent',
          name: '消息',
          dropdown: true,
          dataKey: 'msgModuels',
          countName: 'PC-消息访问'
        }
      ],
      aiDropdownData: [
        {
          name: 'AI智能助手',
          category: '6'
        }, {
          name: 'AI热门文献',
          category: '7'
        }
      ],
      time: null, // 轮询定时器id
      fw2PassCount: 0, // 科普视频密钥总数
      fw10PassCount: 0, // 患教服务密钥总数
      userIds: [1, 4, 8, 12, 508, 137871, 127145, 3467, 7, 9, 139500, 1012786, 1006964, 1015092, 1021754]
    }
  },
  methods: {
    ...mapMutations('user', ['updateToken', 'removeToken', 'setuserInfo']),
    toDrugPage () {
      this.$router.push('/drug')
    },
    getDropdownData (item) {
      return this[item.dataKey]
    },
    dropdownChange (category) {
      const categoryMap = {
        5: {
          isLogin: false,
          path: '/news'
        },
        6: {
          isLogin: true,
          path: '/aiModule',
          query: {
            category
          }
        },
        7: {
          isLogin: false,
          path: '/aiHotDocument',
          query: {
            category
          }
        },
        default: {
          isLogin: true,
          path: '/msgContent',
          query: {
            category
          }
        }
      }
      const countNameMaps = {
        // 1: 'PC-Info团队访问',
        // 2: 'PC-消息反馈访问',
        // 3: 'PC-数据库更新访问',
        // 4: 'PC-活动福利访问',
        6: 'PC-AI助手访问',
        7: 'PC-AI文献访问'
      }
      const find = categoryMap[category] ? categoryMap[category] : categoryMap.default
      if (find.isLogin && !this.token) return this.$login()
      const countName = countNameMaps[category]
      if (countName) this.$myCount(countName)
      if (this.$route.path !== find.path) {
        this.$router.push({
          path: find.path,
          query: find.query
        })
      } else if (find.path === '/msgContent') {
        this.$router.push({
          query: merge(this.$route.query, { category })
        })
      }
    },
    toEnterprisePage () {
      if (!this.token) return this.$login()
      // blankPagePar('http://192.168.0.103:8080', { tk: this.token })
      window.open(`https://admin.infox-med.com/info-enterprise/#/?tk=${this.token}&uid=${this.userId}`)
    },
    getUserFwPassCount () {
      this.getFw2PassCount()
      this.getFw10PassCount()
    },
    // 获取用户科普视频密钥列表
    async getFw2PassCount () {
      const { data } = await getUserFwPassCountByName({
        name: '2'
      })
      this.fw2PassCount = data
    },
    // 获取用户患教服务密钥列表
    async getFw10PassCount () {
      const { data } = await getUserFwPassCountByName({
        name: '10'
      })
      this.fw10PassCount = data
    },
    handleSelect (key, keyPath) {
      if (this.$route.path === key) return
      const navMap = {
        '/literatureKing': {
          isLogin: true
        },
        '/msgContent': {
          isLogin: true,
          query: {
            category: '1'
          }
        },
        '/aiModule': {
          isLogin: true,
          query: {
            category: '6'
          }
        },
        default: {
        }
      }
      const activateNav = navMap[key]
      if (activateNav?.isLogin && !this.token) return this.$login()

      const find = this.optionList.find(item => item.path === key)
      if (find && find.countName) {
        this.$myCount(find.countName)
      }
      if (key === '/aiModule') {
        return window.open(`https://www.infox-med.com/20/#/?token=${this.token}`)
      }

      this.$router.push({
        path: key,
        query: activateNav?.query
      })
    },
    // 登录弹框
    register () {
      this.$login()
    },
    logoutBack () {
      this.removeToken()
      if (this.$route.path === '/') return
      this.$router.push('/')
    },
    // 退出
    personal (type) {
      const action = {
        logout: () => {
          this.isLogin = true
          this.submitUserUseTime(() => {
            localStorage.removeItem('useStartTime')
            localStorage.removeItem('useEndTime')
            this.logoutBack()
          }, () => {
            this.logoutBack()
          })
        },
        userInfo: () => {
          // 跳转个人资料
          if (this.$route.path === '/userInfo/read') return
          pageTurn('userInfo')
        },
        fw2: () => {
          this.$router.push({
            path: '/uploadVideo',
            query: {
              count: this.fw2PassCount
            }
          })
        },
        fw10: () => {
          window.open(`https://www.infox-med.com/20/#/?token=${this.token}&category=2`)
        },
        feedback: () => {
          this.$router.push('/feedback')
        }
      }
      action[type]()
    },
    openLogin (type = 'login') {
      this.$myCount(type === 'login' ? 'PC-登录按钮' : 'PC-注册按钮')
      this.$login({
        type
      })
    },
    // logo跳回首页
    goHome () {
      if (this.$route.path === '/') return
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['token', 'userInfo', 'userId', 'msgModuels']),
    msgMark () {
      return !!this.msgModuels.find(item => item.isMark)
    }
  },
  watch: {
    token (nweval) {
      // console.log(nweval)
      if (!nweval) {
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    // 监听登录弹框状态 关闭移除定时器
    isShow (nweval) {
      if (!nweval) {
        clearInterval(this.time)
      }
    }
  },
  created () {
    if (this.token) {
      this.isLogin = false
      this.getUserFwPassCount()
      this.$store.dispatch('msg/initMark')
    }
  }
}
</script>
<style lang="less" scoped>
.index-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  // height: 82px;
  height: 52px;
  color: #fff;

  .logo {
    display: flex;
    align-items: center;
    width: 440px;
    height: 42px;

    .logo-img {
      width: 140px;
      height: 42px;
    }

    .slogan {
      position: relative;
      // top: -3px;
      font-size: 16px;
      margin-left: 20px;
      letter-spacing: 3px;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    font-size: 18px;
    // font-weight: bold;
    height: 50px;
    color: #4b639f;

    .enterprise_btn {
      margin-right: 10px;
      border-radius: 2px;
      padding: 5px 10px;
      color: #fff;
      font-size: 16px;

      &:hover {
        color: #4b639f;
        background-color: #fff;
        box-shadow: inset 3px 4px 4px 0px rgba(255, 255, 255, 0.3);
        transition: all 1s;
      }
    }

    .login_box {
      position: relative;
      display: grid;
      grid-template-columns: 50px 1px 50px;
      align-items: center;
      height: 30px;
      color: #fff;
      border: 1px solid #fff;
      font-size: 14px;
      border-radius: 5px;

      .login_btn {
        position: relative;
        width: 100%;
        height: 100%;

        &:hover {
          background-color: #fff;
          color: #4b639f;
        }
      }

      .degister_vip_Icon {
        position: absolute;
        top: -5px;
        right: -60px;
        // right: -40px;
        color: #fff;
        background-color: #f0564f;
        font-size: 12px;
        padding: 3px 6px;
        border-radius: 10px 10px 10px 0;
      }

      .line {
        width: 1px;
        background-color: #fff;
        height: 25px;
      }
    }

    .user {
      display: flex;
      align-items: center;

      .tou-xoamng {
        position: relative;
        display: inline-block;
        margin-right: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        // overflow: hidden;
        // box-shadow: 0 0 5px 1px #f6c251;
        .el-image {
          border-radius: 50%;
          overflow: hidden;
        }

        .svg_user_vip {
          position: absolute;
          bottom: -2px;
          right: -7px;
        }

        .team_icon {
          width: 19px;
        }

        .userip_icon {
          width: 19px;
        }
      }

      .image-slot {
        display: inline-block;
        margin-right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        // overflow: hidden;
        & > img {
          width: 40px;
          height: 40px;
        }
      }

      .el-image {
        width: 40px;
        height: 40px;
      }

      .el-dropdown {
        font-size: 20px;
        font-weight: 400;
        color: #fff;

        .el-dropdown-link {
          display: inline-block;
          position: relative;
          top: 3px;
          width: 100px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .el-menu {
      border: 0;
      height: 100%;
      margin-right: 10px;
      background-color: transparent;

      .el-menu-item {
        background-color: transparent;
        height: 50px;
        padding: 0 14px;
        line-height: 54px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 2px;
          margin: 0 auto;
          width: 0%;
          height: 0px;
          background-color: #fff;
          transition: all 0.2s;
        }
      }

      .is-active {
        border-color: transparent !important;
        font-weight: 600;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 2px;
          margin: 0 auto;
          width: 60%;
          height: 3px;
          background-color: #fff;
        }
      }

      .font_weight {
        font-weight: 700;
      }

      .menu-item-name {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: #fff;

        &:hover {
          top: -1px;
        }
      }

      .isMark {
        &::before {
          content: '';
          position: absolute;
          right: -7px;
          top: -7px;
          width: 8px;
          height: 8px;
          background-color: #fc493a;
          border-radius: 50%;
        }
      }
    }
  }

  .not_login {
    padding-right: 66px;
  }
}
</style>
